import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import React from "react";
import { useNavigate } from "react-router-dom";

import "./appAuth.css";
export default function LogIn() {
  React.useState(() => {
    document.title = "CordiaFX- PAMM";
  }, []);

  const navigate = useNavigate();
  const currentYear = new Date().getFullYear(); // Get the current year
  const [hover, setHover] = React.useState(false);


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "0.7rem 2rem",
        }}
      >
        {/* <Language /> */}
      </div>
      <Container className="authContainer" component="main" maxWidth="xs">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/taurex_logo_.png"
            alt="logo"
            style={{ width: "170px", height: "auto", marginTop: "2rem" }}
          />
        </div>
        <p
          className="login-heading"
          style={{
            margin: "auto",
            marginTop: "30px",
            padding: "0 5px",
            textAlign: "center",
          }}
        >
          <span
            style={{
              font: "400 22px/32px Nunito, sans-serif",
              color: "rgb(0 0 0 / 87%)",
              fontWeight: "600",
              fontSize: "22px",
            }}
          >
            CordiaFX- PAMM
          </span>
          <span
            style={{
              opacity: ".5",
              paddingLeft: "10px",
              color: "#6c757d",
              fontWeight: "700",
            }}
          >
            Investor
          </span>
        </p>
        <Box
          className="authBox"
          sx={{
            maxWidth: "33%",
            margin: "auto",
            boxShadow:
              "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)",
            borderRadius: 1,
            px: 2,
            py: 2,
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            paddingTop: "0",
          }}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <p
              style={{
                display: "flex",
                fontSize: "small",
                paddingLeft: "19px",
                marginBottom: "0",
                color: "rgb(0 0 0 / 54%)",
                fontWeight: "600",
              }}
            >
              Account
            </p>

            <Button
              variant="text"
              style={{
                width: "100%",
                height: "56px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "0 1rem",
                color: "rgb(0 0 0 / 87%)",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              onClick={() => navigate("/app/auth/investor")}
            >
              <img
                src="/assets/investor_ico.png"
                alt="logo"
                style={{ width: "26px", height: "22px", marginRight: "5px" }}
              />
              Investor
            </Button>

            <Button
              variant="text"
              style={{
                width: "100%",
                height: "56px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "0 1rem",
                color: "rgb(0 0 0 / 87%)",
                fontWeight: "600",
                textTransform: "capitalize",
                fontSize: "16px",
              }}
              onClick={() => navigate("/app/auth/manager")}
            >
              <img
                src="/assets/manager_ico.png"
                alt="logo"
                style={{ width: "26px", height: "22px", marginRight: "5px" }}
              />
              Money Manager
            </Button>
          </Box>
        </Box>

        {/* <div className="e-con-inner" style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "2rem" }}>
 
          <a href="https://apps.apple.com/us/app/taurex-mobile-trading-broker/id6475347049">
            <img
              decoding="async"
              width="165"
              height="55"
              src="https://d1hnl85qs9kdbt.cloudfront.net/wp-content/uploads/2024/04/29092512/App-Store-EN-1.svg"
              alt="Download on the App Store"
            />
          </a>
    
          <a href="https://play.google.com/store/apps/details?id=com.taurex.app">
            <img
              decoding="async"
              width="187"
              height="55"
              src="https://d1hnl85qs9kdbt.cloudfront.net/wp-content/uploads/2024/04/29092512/Google-Play-EN-1.svg"
              alt="Get it on Google Play"
            />
          </a>
        </div> */}

        <footer
          style={{
            marginTop: "2rem",
            textAlign: "center",
            fontSize: "small",
            color: "#6c757d",
            padding: "1rem",
          }}
        >
          Copyright © {currentYear}{"  "} CordiaFX . All rights reserved.
        </footer>
      </Container>
    </>
  );
}
